import moment from 'moment-timezone'
import { httpHelper } from '@/utils'
import { calculateHumidity } from '@/components/asset-types/cm2-humidity/mixins/humidityCalculation'

const { VUE_APP_COLOR_ACCENT: colorAccent } = process.env

export default {
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            chartData: {},
            sharedChartOptions: {
                chart: {
                    type: 'line',
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        show: false,
                    },
                    animations: {
                        enabled: false,
                    },
                },
                stroke: {
                    curve: 'straight',
                    width: 3,
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                yaxis: [
                    {
                        seriesName: 'humidity',
                        showAlways: true,
                        opposite: true,
                        min: 0,
                        max: 100,
                        tickAmount: 4,
                        decimalsInFloat: 1,
                        title: {
                            text: this.$t('humidityGroup') + ' %',
                        },
                    },
                    {
                        seriesName: 'humidity',
                        show: false,
                        min: 0,
                        max: 100,
                    },
                    {
                        seriesName: 'temperature',
                        showAlways: true,
                        min: -10,
                        max: 300,
                        tickAmount: 4,
                        decimalsInFloat: 1,
                        title: {
                            text: this.$t('temperatureGroup') + ' (℃)',
                        },
                    },
                    {
                        seriesName: 'temperature',
                        show: false,
                        min: -10,
                        max: 300,
                    },
                ],
                tooltip: {
                    shared: true,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
                colors: [
                    colorAccent,
                    '#2E93fA',
                    '#66DA26',
                    '#546E7A',
                    '#E91E63',
                    '#FF9800',
                ],
            },
        }
    },
    computed: {
        temperatureHumiditySeries() {
            let series = []
            let dataEntries = Object.entries(this.chartData)
            for (let [key, series_for_depth] of dataEntries) {
                series.push({
                    name: key,
                    data: series_for_depth,
                })
            }
            series.sort(function(a, b) {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return 0
            })
            for (let item of series) {
                item.name = this.$t(item.name)
            }
            return series
        },
    },
    methods: {
        async loadHistory({ startDate, endDate }) {
            this.isLoading = true
            this.startDate = moment(startDate)
            this.endDate = moment(endDate)
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            const results = await this.loadData()
            this.chartData = this.addHistoryDataFromResponse(results)
            this.isLoading = false
        },
        async loadData() {
            const results = []
            let url = [
                'measurements/?',
                'timestamp_min=' + encodeURIComponent(this.startDate.format()),
                '&timestamp_max=' + encodeURIComponent(this.endDate.format()),
                '&tracker=' + this.id,
                '&fields=timestamp,sensor_data',
                '&limit=' + process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST,
            ].join('')

            while (url) {
                const { data } = await httpHelper.get(url)
                results.push(...data.results)
                url = data.next
            }

            return results
        },
        addHistoryDataFromResponse(results) {
            let chartData = {
                temperature: [],
                t1: [],
                humidity: [],
                ai1: [],
            }

            for (let datapoint of results) {
                let sensorData = datapoint.sensor_data
                let timestamp = datapoint.timestamp
                for (let key of Object.keys(sensorData)) {
                    switch (key) {
                        case 'ai1':
                            chartData[key].push([
                                timestamp,
                                calculateHumidity(sensorData[key]),
                            ])
                            break
                        case 'temperature':
                            chartData[key].push([timestamp, sensorData[key]])
                            break
                        case 'humidity':
                            chartData[key].push([timestamp, sensorData[key]])
                            break
                        case 't1':
                            chartData[key].push([timestamp, sensorData[key]])
                            break
                        default:
                    }
                }
            }
            return chartData
        },
    },
}
