export function calculateHumidity(value) {
    if (value > 3850) {
        value = 90
    } else if (value > 3644) {
        value = (value - 3645) / 6.8333 + 60
    } else {
        value = value / 81 + 15
    }
    value = value.toFixed(2)
    return value
}
