<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <DateRangeInput :value="customRange" @input="loadHistory" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <Spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <ApexCharts
                v-if="!isLoading"
                height="440px"
                :options="sharedChartOptions"
                :series="temperatureHumiditySeries"
            />
        </div>
    </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
import Spinner from 'vue-simple-spinner'
import DateRangeInput from '@/components/DateRangeInput'
import CM2ChartMixin from '@/components/asset-types/cm2-humidity/mixins/CM2ChartMixin'

export default {
    name: 'CM2ChartsView',
    components: {
        ApexCharts,
        DateRangeInput,
        Spinner,
    },
    mixins: [CM2ChartMixin],
}
</script>

<i18n>
{
    "en": {
        "ai1": "External moisture",
        "bottom": "Bottom",
        "humidity": "Internal Moisture",
        "humidityGroup": "Moisture",
        "t1": "External temperature",
        "temperature": "Internal temperature",
        "temperatureGroup": "Temperature",
        "top": "Top"
    },
    "de": {
        "ai1": "Externe Feuchtigkeit",
        "bottom": "Unten",
        "humidity": "Interne Feuchtigkeit",
        "humidityGroup": "Feuchtigkeit",
        "t1": "Externe Temperatur",
        "temperature": "Interne Temperatur",
        "temperatureGroup": "Temperatur",
        "top": "Oben"
    },
    "it": {
        "ai1": "Umidità esterna",
        "bottom": "Sotto",
        "humidity": "Umidità interna",
        "humidityGroup": "Umidità",
        "t1": "Temperatura esterna",
        "temperature": "Temperatura interna",
        "temperatureGroup": "Temperatura",
        "top": "Sopra"
    }
}
</i18n>

<style scoped lang="scss">
.chart-holder {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

.datepicker {
    width: 20%;
    min-width: 350px;
}

@include respond-to('for-tablet-down') {
    .chart-holder {
        display: block;
    }

    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }

    .chart {
        border: none;
    }
}
</style>
